import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";

import {
    Section,
    Title,
    Description,
    QuestionTypeButton,
    ButtonsContainer,
    FaqContainer,
    TitleContainer,
    OpenButton,
    AnswerText,
} from "./styled";

import { categories } from "../../data/faq.tsx";

const FaqSection = () => {
    const [category, setCategory] = useState(0);
    const [open, setOpen] = useState(0);

    useEffect(() => {
        setOpen(0);
    }, [category]);

    return (
        <Section id="faq">
            <Title data-aos="fade-up" data-aos-delay="200">
                F.A.Q.
            </Title>
            <Description data-aos="fade-up" data-aos-delay="200">
                V případě dalších obecných dotazů nás kontaktujte na +420 221
                424 240 nebo{" "}
                <a href="mailto:patria@patria.cz">patria@patria.cz</a>.
            </Description>
            <ButtonsContainer data-aos="fade-up" data-aos-delay="200">
                {categories.map((cat, index) => (
                    <QuestionTypeButton
                        enabled={category === index}
                        onClick={() => setCategory(index)}
                        key={cat.category + index}
                    >
                        <img src={require(`./icons/${cat.icon}`)} />{" "}
                        {cat.category}
                    </QuestionTypeButton>
                ))}
            </ButtonsContainer>
            <div data-aos="fade-up" data-aos-delay="200">
                {categories[category].faqs.map((faq, index) => (
                    <FaqContainer key={`question_${index}`}>
                        <TitleContainer
                            onClick={() =>
                                setOpen(index === open ? null : index)
                            }
                        >
                            <h3>{faq.question}</h3>
                            <OpenButton>
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 4L8 4"
                                        stroke="#000046"
                                        strokeLinejoin="round"
                                    />
                                    {open !== index && (
                                        <path
                                            d="M4 0V8"
                                            stroke="#000046"
                                            strokeLinejoin="round"
                                        />
                                    )}
                                </svg>
                            </OpenButton>
                        </TitleContainer>
                        <Collapse isOpened={open === index}>
                            <AnswerText>{faq.answer}</AnswerText>
                        </Collapse>
                    </FaqContainer>
                ))}
            </div>
        </Section>
    );
};

export default FaqSection;
