import React from "react";

export const categories = [
    {
        category: "Účel dotazníku",
        icon: "ucel.svg",
        faqs: [
            {
                question: "Proč mi předkládáte nový investiční dotazník?",
                answer: (
                    <>
                        <p>
                            Patria Finance, a.s. (dále jen „Patria“) je jako
                            obchodník s cennými papíry povinna Vás na základě
                            právních předpisů požádat o informace o Vašich
                            znalostech a zkušenostech v oblasti investic,
                            finančním zázemí, toleranci k riziku a investičních
                            cílech. Bez těchto informací Patria není oprávněna
                            poskytnout Vám služby investičního poradenství, kdy
                            na jejich základě musí posoudit tzv. vhodnost
                            produktů a služeb, anebo dostatečně vyhodnotit tzv.
                            přiměřenost vybraných investičních služeb a
                            investičních nástrojů.
                        </p>
                        <p>
                            Nový investiční dotazník nahrazuje předchozí verzi
                            dotazníku z roku 2013 a také dotazník cílového trhu.
                        </p>
                    </>
                ),
            },
            {
                question:
                    "Z jakých právních předpisů plyne povinnost dotazování informací a jejich vyhodnocování?",
                answer: (
                    <p>
                        Základ tvoří směrnice Evropského parlamentu a Rady č.
                        2014/65/EU o trzích finančních nástrojů (tzv. MIFID II)
                        a zákon č. 256/2004 Sb., o podnikání na kapitálovém
                        trhu. Existuje celá řada dalších doprovodných předpisů a
                        stanovisek. Investiční dotazník jsme sestavovali
                        samozřejmě v souladu s touto regulací.
                    </p>
                ),
            },
            {
                question: "K čemu použijete odpovědi z dotazníku?",
                answer: (
                    <p>
                        Informace získané z dotazníku poslouží výhradně k tomu,
                        abychom při poskytování investičních služeb a při
                        nabídce investičních nástrojů mohli postupovat maximálně
                        kvalifikovaně a ve Vašem nejlepším zájmu. V zájmu nás
                        všech, Vás i Patrie, je dostatečně vyhodnotit
                        přiměřenost a vhodnost vybraných investičních nástrojů a
                        služeb. Čím úplnější a přesnější informace nám
                        poskytnete, tím lépe Vám budeme schopni nalézt vhodné
                        anebo přiměřené investice.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Obsah dotazníku",
        icon: "obsah.svg",
        faqs: [
            {
                question: "Na co se v dotazníku ptáte?",
                answer: (
                    <p>
                        Dotazník se skládá ze tří částí. První dvě části, ve
                        kterých se dotazujeme na Vaše investiční preference,
                        postoje k rizikům a znalosti investičního prostředí,
                        jsou tvořené téměř výhradně testovými otázkami. U každé
                        otázky je uvedené, je-li jedna správná nebo více
                        správných odpovědí. Při každém vyplňování dotazníku máte
                        na zodpovězení každé otázky tři pokusy, ale pouze
                        v případě, že budete dotazník vyplňovat po internetu.
                        Pokud jej budete vyplňovat v papírové formě, máte pouze
                        jeden pokus. Poslední část dotazníku se týká Vašeho
                        finančního zázemí.
                    </p>
                ),
            },
            {
                question: "Proč se ptáte na finanční zázemí?",
                answer: (
                    <p>
                        Smyslem části dotazující se na Vaše finance je jednak
                        snížení rizika ohrožení Vašeho finančního zázemí, jednak
                        vyhodnocení adekvátnosti zamýšlených investic. Chápeme,
                        že takové otázky bývají nepříjemné, nicméně jsme povinni
                        tyto informace získávat a vyhodnocovat.{" "}
                        <b>
                            Zdůrazňujeme, že Vaše odpovědi z této části
                            dotazníku budou využity výhradně pro vyhodnocení
                            Vašeho finančního zázemí, nikdy ne pro marketingové
                            (reklamní) či jiné účely.
                        </b>{" "}
                        Čím přesnější informace nám poskytnete, tím reálnější
                        představu o svých investičních možnostech získáte.
                        Uvedete-li v odpovědích podstatně vyšší nebo nižší
                        hodnoty, než jaké jsou Vaše skutečné poměry, výsledná
                        celková částka, kterou budete moci investovat, nemusí
                        odpovídat Vašim skutečným investičním možnostem.
                    </p>
                ),
            },
            {
                question:
                    "Obchoduji nebo chci obchodovat na úvěr či na krátko. Liší se v něčem můj dotazník?",
                answer: (
                    <p>
                        Pokud obchodujete na úvěr či na krátko, Váš dotazník
                        bude rozšířený o otázky dotazující se na Vaše znalosti a
                        zkušenosti v této oblasti. Pokud tyto produkty zatím
                        nevyužíváte, ale v budoucnu o ně budete mít zájem, pak
                        si je prosím aktivujte v aplikaci WebTrader v sekci
                        Produkty a oprávnění. Součástí aktivace bude i uzavření
                        příslušné smluvní dokumentace a zodpovězení doplňujících
                        otázek z investičního dotazníku.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Vyplnění",
        icon: "vyplneni.svg",
        faqs: [
            {
                question: "Co se stane, když dotazník nevyplním?",
                answer: (
                    <>
                        <p>
                            K dotazníku můžete zaujmout jeden z následujících
                            tří postojů:
                        </p>
                        <ol type="A">
                            <li>
                                <b>Vyplníte celý dotazník.</b> Vyplnit dotazník
                                pouze částečně nelze. Částečně vyplněný dotazník
                                bude pokládaný za nevyplněný.
                            </li>
                            <li>
                                <b>Odmítnete dotazník jako celek.</b> Nelze
                                odmítnout pouze část dotazníku. Odmítnutí však s
                                sebou nese určité důsledky, viz níže. Kdykoli po
                                odmítnutí dotazníku se můžete rozhodnout pro
                                jeho vyplnění.
                            </li>
                            <li>
                                <b>Nevyplníte dotazník.</b> Pozor, pokud
                                dotazník nevyplníte, neznamená to, že jste jej
                                odmítli! Nevyplnění a odmítnutí jsou dva různé
                                úkony s rozdílnými důsledky, viz níže.
                            </li>
                        </ol>
                        <p>
                            <b>Pokud dotazník odmítnete,</b> má se za to, že:
                        </p>
                        <ul>
                            <li>
                                Nemáte zájem o investiční poradenství (budete
                                obchodovat samostatně).
                            </li>
                            <li>
                                Nestojíte o zvýšenou ochranu spojenou
                                s investičním poradenstvím a vyhodnocením
                                přiměřenosti.
                            </li>
                            <li>
                                Nemáte zájem o vyhodnocení, zda jsou Vaše
                                znalosti a zkušenosti dostatečné pro obchodování
                                konkrétních investičních nástrojů (vyhodnocení
                                přiměřenosti).
                            </li>
                            <li>
                                Nestojíte o nákup komplexních produktů (k
                                obchodování Vám budou dostupné pouze jednoduché
                                produkty, tj. podílové fondy, dluhopisy, akcie,
                                a UCITS ETF).
                            </li>
                        </ul>
                        <p>
                            V tomto případě Vám Patria bude poskytovat služby
                            v tzv. režimu execution-only. Upozorňujeme však, že
                            v rámci tohoto režimu Patria nemusí posuzovat
                            přiměřenost poskytovaného či nabízeného finančního
                            nástroje.
                        </p>
                        <p>
                            <b>Pokud dotazník nevyplníte,</b> budou důsledky
                            záviset na datu uzavření smlouvy mezi Vámi a Patrií.
                        </p>
                        <p>
                            Jste-li klientem se smlouvou uzavřenou před 11. 1.
                            2021 a nevyplníte-li dotazník do 3 měsíců od
                            okamžiku, kdy Vás o jeho vyplnění Patrie požádala,
                            budou Vám po uplynutí této lhůty zablokovány nákupní
                            pokyny. K jejich odblokování je třeba dotazník buď
                            vyplnit, nebo odmítnout.
                        </p>
                        <p>
                            Jste-li klientem se smlouvou uzavřenou po 11. 1.
                            2021, Patria Vám bez vyplněného dotazníku nemůže
                            umožnit obchodování. Po jeho vyplnění Vám Patria
                            umožní obchodování v takovém rozsahu, který bude
                            respektovat jeho vyhodnocení.
                        </p>
                    </>
                ),
            },
            {
                question:
                    "Kde mohu dotazník vyplnit? Kde mohu dotazník vyplnit poté, co jsem jej odmítl/a?",
                answer: (
                    <>
                        <ul>
                            <li>
                                Fyzické osoby (včetně disponentů) mají dotazník
                                k dispozici ihned po přihlášení do aplikace
                                WebTrader nebo v sekci Můj profil / Investiční
                                dotazník. Zde dotazník čeká i na ty, kteří jej
                                budou chtít vyplnit poté, co jej nejprve
                                odmítli.
                            </li>
                            <li>
                                Právnické osoby (včetně těch, které dotazník
                                nejprve odmítly) naleznou dotazník ve formátu
                                editovatelného PDF v aplikaci WebTrader v sekci
                                Můj profil / Investiční dotazník, a to včetně
                                obecných instrukcí k jeho vyplnění. Vyplněný
                                dotazník následně nahrají prostřednictvím této
                                aplikace nebo nám jej zašlou e-mailem. Za
                                právnickou osobu vyplňuje dotazník statutární
                                orgán.
                            </li>
                        </ul>
                        <p>
                            Ihned po vyplnění dotazníku se Vám zobrazí jeho
                            vyhodnocení. Až se s vyhodnocením seznámíte, je
                            třeba jej potvrdit pomocí zaškrtávacího pole a
                            v dalším kroku autorizačním kódem z SMS anebo
                            bezpečnostní klíčenky.{" "}
                            <b>
                                Pozor, bez těchto potvrzení dotazník nebude
                                pokládaný za vyplněný!
                            </b>{" "}
                            Za vyplněný dotazník pokládáme ten dotazník, jehož
                            všechny otázky byly zodpovězené a jehož vyhodnocení
                            bylo potvrzené.
                        </p>
                    </>
                ),
            },
            {
                question:
                    "Chci obchodovat s makléřem (stojím o investiční poradenství). Musím vyplnit dotazník?",
                answer: (
                    <p>
                        Ano, bez vyplněného dotazníku Patria není oprávněna
                        poskytnout Vám službu investičního poradenství. Patria
                        Vám rovněž nemůže poskytnout investiční poradenství na
                        instrumenty, u kterých nemáte dostatečné znalosti a
                        zkušenosti, nejste ochoten/a akceptovat jejich
                        specifická rizika anebo nést riziko případné ztráty.
                        Více o službě investičního poradenství se dočtete{" "}
                        <a
                            href="https://www.patria-finance.cz/sluzby/investicni-poradenstvi-maklere"
                            target="_blank"
                        >
                            zde
                        </a>
                        .
                    </p>
                ),
            },
            {
                question:
                    "Jsem disponentem k účtu klienta – fyzické/právnické osoby a chci obchodovat na tento účet. Musím vyplnit dotazník?",
                answer: (
                    <p>
                        Ano, musíte, a to nejen Vy, ale i klient –
                        fyzická/právnická osoba, k jehož účtu jste disponentem.
                        Disponenti mají dotazník k dispozici ihned po přihlášení
                        do aplikace WebTrader nebo v sekci Můj profil /
                        Investiční dotazník. Po jeho vyplnění mohou na účet
                        fyzické/právnické osoby provádět ty investice, které
                        jsou jednak přiměřené jejich znalostem a zkušenostem,
                        jednak slučitelné s finančním zázemím, investičními
                        preferencemi a cíli fyzické/právnické osoby.
                    </p>
                ),
            },
            {
                question: "Kolik času mi zabere vyplnění dotazníku?",
                answer: <p>Asi 10 min.</p>,
            },
            {
                question:
                    "Mohou mi s vyplněním dotazníku pomoci zaměstnanci Patria Finance, např. můj makléř?",
                answer: (
                    <p>
                        Zaměstnanci Patrie nejsou oprávněni pomáhat Vám
                        s vyplněním dotazníku, ani Vás jakkoli navádět.
                        V případě obecných dotazů k dotazníku, týkajících se
                        např. jeho vyhodnocení a jejich dopadů na Vaše
                        obchodování, nás můžete kontaktovat na +420 221 424 240
                        nebo{" "}
                        <a href="mailto:patria@patria.cz">patria@patria.cz</a>.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Vyhodnocení",
        icon: "vyhodnoceni.svg",
        faqs: [
            {
                question: "Kde najdu vyhodnocení dotazníku?",
                answer: (
                    <>
                        <p>
                            Vyhodnocení dotazníku se Vám zobrazí ihned po jeho
                            vyplnění. Až se s ním seznámíte, je třeba jej
                            potvrdit pomocí zaškrtávacího pole a v dalším kroku
                            autorizačním kódem z SMS anebo bezpečnostní
                            klíčenky. Po potvrzení bude vyplněný dotazník včetně
                            jeho vyhodnocení uložený v aplikaci WebTrader v
                            sekci / Můj profil / Dokumenty. Pozor, bez potvrzení
                            vyhodnocení se dotazník neuloží a nebude pokládaný
                            za vyplněný!
                        </p>
                        <p>
                            Po vyplnění, potvrzení a uložení dotazníku bude jeho
                            rámcové vyhodnocení dostupné v aplikaci WebTrader
                            v sekci Můj profil / Produkty a oprávnění.
                        </p>
                    </>
                ),
            },
            {
                question: "Co najdu ve vyhodnocení dotazníku?",
                answer: (
                    <>
                        <p>
                            Ve vyhodnocení svého dotazníku naleznete
                            následující:
                        </p>
                        <ol>
                            <li>Váš výsledný rizikový profil.</li>
                            <li>
                                Vaše investiční cíle, které při investování
                                sledujete.
                            </li>
                            <li>
                                <p>
                                    Vhodnost, přiměřenost a cílový trh vybraných
                                    investičních nástrojů.
                                </p>
                                <p>
                                    Tato vyhodnocení jsou uvedená v přehledné
                                    tabulce, přičemž klíčový je zejména sloupec
                                    „Umožněno k obchodování“. Zde se dozvíte,
                                    které produkty a služby Vám na základě
                                    Vašich odpovědí z dotazníku Patria umožní
                                    obchodovat.
                                </p>
                                <p>
                                    Pokud využíváte investiční poradenství,
                                    prosím věnujte zvýšenou pozornost i sloupci
                                    „Poradenství“. Tento sloupec Vám přináší
                                    informaci o instrumentech, u kterých můžete
                                    využít službu investičního poradenství.
                                </p>
                            </li>
                            <li>
                                Výši částky, kterou u Patrie můžete investovat,
                                a výši finanční rezervy, kterou byste měl/a
                                vyloučit z prostředků zamýšlených k investování.
                            </li>
                        </ol>
                    </>
                ),
            },
            {
                question:
                    "Pokud chci ve vyplněném dotazníku změnit některou z odpovědí, mohu tak učinit? Pokud ano, jak?",
                answer: (
                    <p>
                        Ano, můžete, a to již po uplynutí 24 hodin od jeho
                        posledního vyplnění. Úpravu můžete provést v aplikaci
                        WebTrader v sekci Můj profil / Investiční dotazník.
                        Nevyplňujete znovu celý dotazník, pouze upravíte
                        odpovědi, které si přejete změnit.
                    </p>
                ),
            },
            {
                question:
                    "Jsem disponentem k účtu klienta – fyzické/právnické osoby. Jak ovlivní vyhodnocení našich dotazníků (mého a klienta – fyzické/právnické osoby) obchodování na účet této fyzické/právnické osoby?",
                answer: (
                    <>
                        <p>
                            Když budete jakožto disponent podávat pokyn na účet
                            fyzické/právnické osoby, dojde u zamýšlené investice
                            k posouzení:
                        </p>
                        <ol>
                            <li>
                                Přiměřenosti vzhledem k  Vašim znalostem a
                                zkušenostem.
                            </li>
                            <li>
                                Slučitelnosti s finančním zázemím, investičními
                                preferencemi a cíli fyzické/právnické osoby.
                            </li>
                        </ol>
                        <p>
                            Dojde-li ke zjištění, že zamýšlená investice není
                            přiměřená či slučitelná, Patria tuto investici nesmí
                            umožnit.
                        </p>
                    </>
                ),
            },
            {
                question:
                    "Nerozumím některým pojmům ve vyhodnocení dotazníku. Co znamenají?",
                answer: (
                    <p>
                        V závěru každého vyhodnocení dotazníku jsou vysvětlivky
                        a legenda, kde naleznete vysvětlení pojmů, které Vám
                        mohou být nesrozumitelné. Tato vysvětlení najdete i na
                        této stránce v sekci Vybrané pojmy.
                    </p>
                ),
            },
            {
                question:
                    "Chci vědět víc o investování. Kde zjistím více informací?",
                answer: (
                    <p>
                        V naší{" "}
                        <a
                            href="https://www.patria-finance.cz/vzdelavani/zaciname-s-investovanim/skola-investora"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Škole investora
                        </a>{" "}
                        jsou Vám ZDARMA k dispozici webináře a e-booky pro
                        začínající i pokročilé investory, díky kterým se můžete
                        o investování dozvědět mnohem víc.
                    </p>
                ),
            },
        ],
    },
    {
        category: "Platnost",
        icon: "platnost.svg",
        faqs: [
            {
                question: "Po jak dlouhou dobu je platný vyplněný dotazník?",
                answer: (
                    <>
                        <p>
                            Část dotazníku týkající se finančního zázemí
                            expiruje vždy po 1 roce. Platnost celého dotazníku
                            pak vyprší nezávisle na části ohledně finančního
                            zázemí podle toho, jaké bude vyhodnocení Vašeho
                            výsledného rizikového profilu.
                        </p>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "8px",
                                }}
                            >
                                <p style={{ flex: "0 1 150px" }}>
                                    Rizikový profil
                                </p>
                                <p>Expirace po uplynutí</p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: "0 1 150px" }}>
                                    Velmi odvážný
                                </p>
                                <p>1 roku</p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: "0 1 150px" }}>Odvážný</p>
                                <p>2 let</p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <p style={{ flex: "0 1 150px" }}>Opatrný</p>
                                <p>3 let</p>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                question:
                    "Můj dotazník (nebo jeho část) brzy expiruje/již expiroval (byly mi zablokovány nákupní pokyny). Co mám dělat?",
                answer: (
                    <>
                        <p>
                            Před vypršením platnosti Vašeho dotazníku (nebo jeho
                            části) Vás s dostatečným časovým předstihem
                            upozorníme na potřebu aktualizace i způsob, jak ji
                            provést. Při aktualizaci nevyplňujete znovu celý
                            dotazník; pouze upravíte odpovědi, které již
                            neodpovídají skutečnosti, případně potvrdíte
                            aktuálnost dotazníku.
                        </p>
                        <ul>
                            <li>
                                Fyzické osoby (včetně disponentů) budou mít
                                investiční dotazník k dispozici ihned po
                                přihlášení do aplikace WebTrader, nebo v sekci
                                Můj profil / Investiční dotazník.
                            </li>
                            <li>
                                Právnické osoby naleznou dotazník ve formátu
                                editovatelného PDF v aplikaci WebTrader v sekci
                                Můj profil / Investiční dotazník, a to včetně
                                instrukcí k jeho vyplnění a zaslání.
                            </li>
                        </ul>
                        <p>
                            Dotazník (nebo jeho část) je třeba aktualizovat
                            nejpozději do 3 měsíců po jeho expiraci, jinak dojde
                            k omezení nákupních pokynů. Pro jejich odblokování
                            je třeba provést aktualizaci dotazníku (nebo jeho
                            části).
                        </p>
                    </>
                ),
            },
        ],
    },
    {
        category: "Vybrané pojmy",
        icon: "pojmy.svg",
        faqs: [
            {
                question: "Cílový trh",
                answer: (
                    <>
                        <p>
                            <span style={{ color: "#46E08C" }}>Pozitivním</span>{" "}
                            cílovým trhem je skupina zákazníků, jejichž
                            investiční preference a vlastnosti se shodují s
                            investičními preferencemi a vlastnostmi zákazníka,
                            pro kterého je konkrétní investiční produkt určen
                            jeho tvůrcem. V případě, že jste zákazníkem v
                            pozitivním cílovém trhu pro daný investiční produkt,
                            Patria Vám investiční produkt může bez dalšího
                            umožnit k nákupu nebo aktivně nabízet.
                        </p>
                        <p>
                            <span style={{ color: "#F63838" }}>Negativním</span>{" "}
                            cílovým trhem je naopak skupina zákazníků, jejichž
                            investiční preference a vlastnosti se shodují s
                            investičními preferencemi a vlastnostmi zákazníka,
                            pro kterého není konkrétní investiční produkt určen
                            jeho tvůrcem. V případě že jste zákazníkem v
                            negativním cílovém trhu pro daný investiční produkt,
                            Patria Vám investiční produkt nemůže umožnit k
                            nákupu ani aktivně nabízet.
                        </p>
                        <p>
                            <span style={{ color: "#B9B9B9" }}>Neutrálním</span>{" "}
                            cílovým trhem je skupina zákazníků, jejichž
                            investiční preference a vlastnosti se neshodují s
                            investičními preferencemi a vlastnostmi zákazníka
                            nacházejícím se v pozitivním nebo negativním cílovém
                            trhu určeném tvůrcem pro daný investiční produkt. V
                            případě že jste pro daný investiční produkt
                            zákazníkem v neutrálním cílovém trhu, investiční
                            produkt Vám Patria umožní k nákupu jen ve vybraných
                            režimech v závislosti na důvodu zařazení do
                            neutrálního trhu, avšak nemůže jej aktivně nabízet.
                        </p>
                        <p>
                            Na to, aby Patria vyhodnotila, zda jste
                            pozitivním/neutrálním/negativním cílovým trhem a zda
                            spadáte do daného okruhu zákazníků či nikoliv, musí
                            zjistit zejména: jaký typ zákazníka jste, jaké máte
                            znalosti a zkušenosti, jaké je Vaše finanční zázemí,
                            jaká je Vaše tolerance k riziku a v neposlední řadě
                            Vaše investiční cíle.
                        </p>
                    </>
                ),
            },
            {
                question: "Přiměřenost",
                answer: (
                    <>
                        <p>
                            Přiměřenost investičního produktu nebo investiční
                            služby pro zákazníka je vyhodnocována na základě
                            jeho znalostí a zkušeností s daným investičním
                            produktem nebo investiční službou. Patria považuje
                            investiční produkt pro klienta přiměřený nebo
                            investiční službu přiměřenou pouze v případě, pokud
                            jsou zákazníkovi znalosti a zkušenosti s daným
                            investičním produktem či službou dostatečné pro to,
                            aby zákazník dostatečně pochopil jejich podstatu
                            včetně relevantních rizik. V návaznosti na míru
                            komplexity a složitosti produktu či služby může být
                            pro určení přiměřenosti po zákazníkovi vyžadován
                            různý rozsah jeho znalostí a zkušeností.
                        </p>
                    </>
                ),
            },
            {
                question: "Rizikový profil",
                answer: (
                    <p>
                        Rizikový profil zákazníka je definován jako přístup a
                        postoj zákazníka k rizikům investice a potenciálním
                        výkyvům na finančních trzích. Rizikový profil Patria
                        vyhodnocuje na základě informací, které jí zákazník
                        poskytnul ve vztahu k zamýšlenému investičnímu horizontu
                        a ve vztahu k jeho chování v modelových situacích
                        týkajících se propadu v hodnotě zakoupených investičních
                        nástrojů. Patria progresivně vyhodnocuje tyto atributy
                        kombinovaně za předpokladu, že riziko dané investice je
                        v delším časovém horizontu nižší než v kratším časovém
                        horizontu a naopak. Zákazníkovi s daným rizikovým
                        profilem je umožněno obchodovat pouze s takovými
                        investičními nástroji, které jsou pro jeho rizikový
                        profil určeny. Skutečnost, který investiční nástroj je
                        určen pro který rizikový profil, je určena na základě
                        indikátorů rizikovosti/návratnosti investice a tedy
                        volatility (SRI/SRRI) určeným tvůrcem investičního
                        nástroje, nebo na základě rozhodnutí Patrie v souladu
                        s jejími zkušenostmi a znalostmi finančních trhů.
                    </p>
                ),
            },
            {
                question: "Vhodnost (investiční poradenství)",
                answer: (
                    <p>
                        Vyhodnocení vhodnosti je podstatou služby investičního
                        poradenství. K tomu, aby Vám mohl makléř poskytnout
                        investiční poradenství, je nezbytné, aby pro Vás byl
                        daný investiční produkt nebo investiční služba vhodná.
                        Je-li pro Vás investiční služba nebo investiční produkt
                        vhodný, znamená to, že Vaše znalosti a zkušenosti,
                        finanční zázemí, postoj k riziku a investiční cíle byly
                        zhodnoceny jako dostatečné. V tomto případě Vám může
                        makléř poskytnout investiční poradenství.
                    </p>
                ),
            },
        ],
    },
];
