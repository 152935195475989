import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";

import MainPage from "../containers/MainPage";

const GlobalStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 500ms;
    }
`;

const IndexPage = () => {
    return (
        <>
            <GlobalStyles />
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>Investiční doporučení - Patria Finance</title>
            </Helmet>
            <MainPage />
        </>
    );
};

export default IndexPage;
