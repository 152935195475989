import React from "react";
import MaxWidthContainer from "../../components/MaxWidth";
import AboutSection from "../../components/AboutSection";
import BigImageSection from "../../components/BigImageSection";
import FaqSection from "../../components/FaqSection";

import about from "../../images/about-paper.png";
import computer from "../../images/dictionary-computer.png";
import charts from "../../images/dictionary-charts.png";
import { smoothScroll } from "../../../utils/util";

const MainPage = () => {
    return (
        <MaxWidthContainer>
            <AboutSection
                id="about"
                paragraph="Dobrá pomoc při volbě investic"
                title="Nový investiční dotazník"
                underlayColor="#F59100"
                imageUrl={about}
                buttonText="Pokračovat"
                onClick={() => smoothScroll("faq", 140)}
            />
            <FaqSection id="faq" />
            <BigImageSection
                id="bigImage"
                title="Patria Slovník"
                subTitle="Learn More"
                underlayColor="#F59100"
                smallImageUrl={charts}
                bigImageUrl={computer}
            />
        </MaxWidthContainer>
    );
};

export default MainPage;
